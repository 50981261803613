import { ChainId } from 'spaceswap-sdk-berachain-test'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.BERACHAINARTIO]: '0x81aC69372884166Dff86649325c1E5F2C39b6102',


}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
